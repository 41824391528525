import React from 'react';

const NoMatch = () => {
  return (
    <main>
      <h1 class="text-center">Oops, we couldn't find that page.</h1>
    </main>
  );
};

export default NoMatch;